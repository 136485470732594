<template>
    <div class="content-box">
        <el-card class="my-20">
            <div class="d-flex-center">
                <div class="d-inline-flex-center is-link" @click="$nav.back()">
                    <i class="icon icon-back my-8 mr-8" />
                    <span class="color-666">{{ $t('back') }}</span>
                </div>
                <div class="font-16 ml-20 pl-20 mr-auto font-bold color-666 border-left">{{ $t('subscribe.project_details') }}</div>
                <el-button type="primary" icon="el-icon-refresh" plain @click="onRefresh()">{{ $t('refresh') }}</el-button>
            </div>
        </el-card>
        <el-card class="mb-30">
            <el-row :gutter="0">
                <el-col :span="14">
                    <div class="pr-20 border-right" v-loading="loading">
                        <div class="d-flex-center pb-15 border-bottom">
                            <el-avatar class="mr-20" shape="square" :size="48" :src="info.logo" />
                            <span class="mr-auto font-24 notranslate">{{ info.coin }}</span>
                            <el-tag v-if="info.status == 0" size="medium" effect='plain' class="scale-150">{{ $t('subscribe.wtb') }}</el-tag>
                            <el-tag v-else-if="info.status == 1" size="medium" effect='plain' class="scale-150">{{ $t('subscribe.Subscring') }}</el-tag>
                            <el-tag v-else-if="info.status == 2" size="medium" effect='plain' class="scale-150">{{ $t('subscribe.wtm') }}</el-tag>
                            <el-tag v-else-if="info.status == 3" size="medium" effect='plain' class="scale-150">{{ $t('subscribe.on_market') }}</el-tag>
                            <el-tag v-else>{{ $t('subscribe.has_ended') }}</el-tag>
                        </div>
                        <div class="d-flex-center py-15 font-bold border-bottom">
                            <span class="label mr-auto">{{ $t('subscribe.issue_quota') }} : </span>
                            <span class="notranslate">{{ info.num }} {{ info.coin }}</span>
                        </div>
                        <div class="d-flex-center py-15 font-bold border-bottom">
                            <span class="label mr-auto">{{ $t('subscribe.current_price') }} : </span>
                            <span class="notranslate">{{ info.now_price }} USDT</span>
                            <span class="mx-5 color-p">≈</span>
                            <span class="color-p notranslate">1 {{ info.coin }}</span>
                        </div>
                        <div class="d-flex-center py-15 font-bold border-bottom">
                            <span class="label mr-auto">{{ $t('subscribe.sd') }} : </span>
                            <span class="notranslate">{{ info.release_date }}</span>
                            <span class="mx-5">{{ $t('subscribe.to') }}</span>
                            <span class="notranslate">{{ info.expiration_date }}</span>
                        </div>
                        <div class="d-flex-center py-15 font-bold border-bottom">
                            <span class="label mr-auto">{{ $t('subscribe.st') }} : </span>
                            <span class="mx-5">{{ $t('subscribe.daily') }}</span>
                            <span class="notranslate">{{ info.subscribe_time_start }}</span>
                            <span class="mx-5">{{ $t('subscribe.to') }}</span>
                            <span class="notranslate">{{ info.subscribe_time_end }}</span>
                        </div>
                        <div class="d-flex-center pt-15 font-bold">
                            <span class="label mr-auto">{{ $t('subscribe.std') }} : </span>
                            <span class="notranslate">{{ info.start_date }}</span>
                        </div>
                    </div>
                </el-col>
                <el-col :span="10">
                    <el-form
                            v-loading="formLoading"
                            ref="ruleForm"
                            class="white-form pl-20"
                            :model="formData"
                            :show-message="false"
                            :status-icon="true"
                            :rules="rules"
                        >
                            <div class="font-16 d-flex-center font-bold pb-15 mb-20 border-bottom" style="line-height: 48px;">
                                <span class="mr-auto">{{ $t('subscribe.available') }} USDT :</span>
                                <span>{{ buyInfo.usdt }}</span>
                            </div>
                            <el-form-item prop="amount">
                                <div class="d-flex-center">
                                    <el-input v-model="formData.amount" prefix-icon="el-icon-coin" :placeholder="$t('subscribe.eq')">
                                        <span slot="append">{{ info.coin }}</span>
                                    </el-input>
                                    <el-button type="primary" plain @click="setMax()">{{ $t('MAX') }}</el-button>
                                </div>
                            </el-form-item>
                            <el-form-item prop="email_captcha">
                                <div class="d-flex-center">
                                    <el-input v-model="formData.email_captcha" prefix-icon="el-icon-unlock" :placeholder="$t('user.email_captcha')" />
                                    <el-button type="primary" plain @click="sendEmailCaptcha()">{{ $t('send') }}</el-button>
                                </div>
                            </el-form-item>
                            <div class="my-15 pt-10 font-16 d-flex-center">
                                <div class="mr-auto">{{ $t('subscribe.total') }} USDT : <span class="font-bold" v-text="totalUsdt" /></div>
                                <div>{{ $t('subscribe.lock_period') }} : {{ info.cycle }} {{ $t('subscribe.months') }}</div>
                            </div>
                            <el-form-item class="m-0">
                                <el-button v-if="!$store.state.userToken" type="warning" icon="el-icon-user" class="w-100" native-type="button" @click="$nav.push('/user/login')">{{ $t('login_first') }}</el-button>
                                <el-button v-else-if="info.status < 2" type="primary" icon="el-icon-s-promotion" class="w-100" native-type="button" @click="subscribeForm()">{{ $t('subscribe.Subscribe') }}</el-button>
                                <el-button v-else :disabled="info.status != 3" type="primary" icon="el-icon-s-promotion" class="w-100" native-type="button" @click="submitForm()">{{ $t('subscribe.get_involved_now') }}</el-button>
                            </el-form-item>
                        </el-form>
                </el-col>
            </el-row>
        </el-card>
        <el-card class="mb-20">
            <div slot="header" class="clearfix">
                <div class="d-flex-center">
                    <span class="mr-auto font-18 my-8">{{ $t('subscribe.project_des') }}</span>
                    <a :href="info.white_paper" target="_blank">
                        <el-button :disabled="!info.white_paper" icon="el-icon-document" type="primary" plain>{{ $t('subscribe.white_paper') }} >></el-button>
                    </a>
                </div>
            </div>
            <div v-html="info.des" class="rich-view" />
        </el-card>
        <el-card class="mb-30 color-black color-d text-center warning-box">
            <i class="el-icon-warning-outline" />
            Digital assets are innovative investment products, which may have problems such as pre mining of miners, manoeuvring of makers, team dissolution, technical defects, etc.; their prices fluctuate greatly, which has high investment risk. Please fully understand the digital assets before investment, judge your investment ability rationally, and make investment decisions prudently.
        </el-card>
    </div>
</template>

<script>
import { float as regexpFloat, number as regexpNumber } from '@/services/regexp'
export default {
    data() {
        return {
            loading: false,
            info: {
                coin: '',
                now_price: '0',
                des: ''
            },
            buyInfo: {
                usdt: '0.00000000'
            },
            timer: null,
            formLoading: false,
            formData: {
                coin: this.$route.params.coin,
                amount: '',
                email_captcha: ''
            },
            rules: {
                amount: [
                    { required: true, pattern: regexpFloat, trigger: 'blur', message: this.$t('wallet.pea') }
                ],
                email_captcha: [
                    { required: true, pattern: regexpNumber, min: 6, trigger: 'blur' }
                ]
            }
        }
    },
    computed: {
        totalUsdt(){
            if(!this.formData.amount){
                return '--'
            }
            return (this.info.now_price * this.formData.amount).toFixed(8) * 1
        }
    },
    created: function () {
        this.fatchData()
        this.fatchBuyInfo()
        this.timer = window.setInterval(() => {
            if (document.hidden) return
            this.fatchPrice()
        }, 2000)
    },
    beforeDestroy: function () {
        window.clearInterval(this.timer)
    },
    methods: {
        onRefresh: function (){
            this.fatchData()
            this.fatchBuyInfo()
        },
        sendEmailCaptcha: async function (){
            this.formLoading = true
            try{
                await this.$request.get('user/email_captcha')
            }catch(e){
                //TODO handle the exception
            }
            this.formLoading = false
        },
        fatchData: async function () {
            this.loading = true
            const { data } = await this.$request.get('coin/item/coin/' + this.$route.params.coin)
            this.info = data
            this.loading = false
        },
        setMax: function(){
            if(this.buyInfo.usdt <= 0){
                this.formData.amount = ''
                return
            }
            const max = Math.floor(this.buyInfo.usdt / this.info.now_price * 0.999)
            if(max >= this.info.buy_top){
                this.formData.amount = this.info.buy_top * 1
                return
            }
            this.formData.amount = max
        },
        fatchBuyInfo: async function () {
            if(!this.$store.state.userToken) return
            if(this.formLoading) return
            this.formLoading = true
            try {
                const { data } = await this.$request.get('coin/info/coin/' + this.$route.params.coin)
                this.buyInfo = data
            } catch (error) {
                //
            }
            this.formLoading = false
        },
        fatchPrice: async function () {
            const { data } = await this.$request.get('coin/price/coin/'+this.$route.params.coin)
            this.info.now_price = data
        },
        submitForm: async function (){
            if(this.formLoading) return
            try {
                await this.$refs.ruleForm.validate()
                this.formLoading = true
                await this.$request.post('coin_buy_log/submit', this.formData)
                this.formData.amount = ''
                this.formData.email_captcha = ''
            } catch (error) {
                //
            }
            this.formLoading = false
        },
        subscribeForm: async function (){
            if(this.formLoading) return
            try {
                await this.$refs.ruleForm.validate()
                this.formLoading = true
                await this.$request.post('coin_buy_log/subscribe', this.formData)
                this.formData.amount = ''
                this.formData.email_captcha = ''
            } catch (error) {
                //
            }
            this.formLoading = false
        }
    }
}
</script>
<style>
.white-form input{
    color: black !important;
}
</style>
<style lang="scss">
.coin-logo{
    object-fit: fill;
    height: 220px;
}
.price-box{
    background-color: #f1f1f3;
}
.rich-view{
    p{
        line-height: 24px;
        text-indent:  2em;
    }
    img{
        max-width: 100%;
    }
}
.warning-box{
    line-height: 24px;
}
.scale-150{
    transform: scale(1.5);
    margin-right: 16px;
}
</style>